@import '../../../../assets/scss/variables';

.UnfoldIcon {
  color: $primary;
  font-size: 27px;
  padding-right: 50px;
}

.DemoButton {
  border-radius: 5px;
  height: 40px;
  text-align: center;
  width: 100%;
}
