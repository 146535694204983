@import '../../../assets/scss/variables';

.Container {
  background-color: white;
  font-size: 16px;
  font-weight: 400;
  height: 27px;
  padding-bottom: 22px;
  padding-top: 30px;
  position: sticky;
  text-align: left;
  top: 0;
  width: 100%;
  z-index: 100;
  z-index: 1000;
}

.Logo {
  height: 26px;
  width: auto;
}

.Sticky {
  background-color: white;
  height: 84px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.Transparent {
  background-color: transparent !important;
}

.Button {
  border-radius: 5px;
  height: 40px;
  
}

.Unscrolled {
  background-color: white;
  color: $primary;
 
}

.NavLink {
  color: $dark-text;
  

  &:hover {
    color: $primary;
  }
}

.CurrentLocation {
  color: $primary;
}


