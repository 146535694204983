@import '../../../assets/scss/variables';

.Car {
  height: 100%;
  width: 100%;
}

.Title {
  margin: 12px 0 8px;
}

.Description {
  color: $gray-text;
  margin-bottom: 12px;
}

.Button {
  background: $primary;
  border-color: $primary;
  border-radius: 5px;
  padding-bottom: 30px;
  padding-top: 10px;
  width: 140px;

  &:hover {
    background: $dark-primary;
    border-color: $dark-primary;
  }
  
  &:focus {
    background: $primary;
    border-color: $primary;
  }
}
