.DropDownIcon {
  height: 4.5px;
  width: 9px;
}

.DynamicColor {
  background: inherit;
}

.Dropdown {
  min-width: 0;
}

.DropdownItems {
  min-width: 0;
}

