@import '../../../assets/scss/variables';

.Wrapper {
  background-color: $dark-text;
  margin-top: auto;
}

.Container {
  padding-bottom: 24px;
  padding-top: 48px;

  h6 {
    color: white;
    margin-bottom: 5px;
    text-align: start;
  }
}

.MarginTop {
  margin-top: 10vh;
}

.Logo {
  height: 52px;
}

.FooterLink {
  color: white;
  font-size: 14px;
  margin-top: 12px;
  text-align: start;

  &:hover {
    color: $secondary;
  }

  &:focus {
    color: $secondary;
  }
}

.Licence {
  color: white;
  text-align: center;
  text-align: start;
}
