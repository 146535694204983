/* stylelint-disable function-parentheses-space-inside */
body {
  --primary: #4628e6;
  --pale-primary: #f5f0ff;
  --dark-primary: #2b17bf;
  --primary-focus-outline: #2b17bf50;
}

$primary: var(--primary);
$pale-primary: var(--pale-primary);
$dark-primary: var(--dark-primary);
$primary-focus-outline: var(--primary-focus-outline); // focus buttons

$dark-text: #272638;
$secondary: #3cbec0;
$primary-focus-outline: #2b17bf50;
$background: white;

// GRAY SCALE
$pale-gray: #f9f9fb;
$gray-text: #8c8c8c; // gray-7
$gray-6: #bfbfbf; // steps
$border-gray: #d9d9d9;
$background-gray: whitesmoke; // #f5f5f5

// ALERTS
$error-red: #ff4d4f;
$warning-yellow: #ffc53d;
$succcess-green: #52c41a;
$info-blue: #1890ff;
