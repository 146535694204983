/* stylelint-disable selector-class-pattern */
.Container {
  height: fit-content;
  padding-left: 5%;
  padding-right: 5%;
  text-align: justify;
}

.addedPadding {
  padding-left: 10%;
  padding-right: 10%;
}
