@import '~@cloudcar-app/cloudcar-components/styles/index';
@import '../scss/variables';
@import '../scss/fonts';
@import '../scss/breakpoints';

body {
  font-family: Roboto, sans-serif;
  height: 100%;
  margin: 0;
}

// Fonts configurations
h1 {
  color: $dark-text;
  font-family: Roboto, sans-serif;
  font-size: 30px;
  font-weight: 500;
  line-height: 35px;
  margin: 0;
  padding: 0;
  text-align: left;
  @media only screen and (max-width: $sm) {
    font-size: 32px;
    line-height: 40px;
  }
}

h2 {
  color: $dark-text;
  font-family: Roboto, sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  margin: 0;
  padding: 0;
  text-align: left;
  @media only screen and (max-width: $sm) {
    font-size: 26px;
  }
}

h3 {
  color: $dark-text;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  margin: 0;
  padding: 0;
  text-align: left;
}

h5 {
  color: $dark-text;
  font-family: Roboto, sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 21px;
  margin: 0;
  padding: 0;
  text-align: left;
}

h6 {
  color: $dark-text;
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  margin: 0;
  padding: 0;
  text-align: left;
}

p {
  color: $dark-text;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
  padding: 0;
  text-align: left;
}

a {
  font-size: 16px;
}

// Ant Design Components
/* stylelint-disable */

#root {
  height: 100%;
}
.ant-menu-item-only-child{
  &:hover{
    span{
     color: $primary !important;
    }
  }
  &:active{
    span{
     color: $primary !important;
    }
  }
}
.ant-menu-item-active {
  &:hover {
    background-color: transparent;
    span{
      color: $primary !important;
    }
  }
  border: 0;
  &:after{
    border: 0;
  } 
}
.ant-menu-item:active {
  background-color: transparent;
  span{
    color: $primary !important;
  }
  &:after{
    border: 0;
  }
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent;
  span{
    color: $primary !important;
  }
  border: 0;
  &:after{
    border: 0;
  }
}

.ant-menu-submenu-arrow {
 color: $dark-text;

 &:after{
  color: $dark-text;
 }
 &:before{
  color: $dark-text;
 }
}

.ant-menu-sub.ant-menu-inline{
  background: white;
}

.ant-collapse-header {
  padding-left: 48px !important;
  padding-top: 10px !important;
}

.ant-modal-body{
  padding: 0 !important;
}

.ant-btn {
  color: 'white';
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
  text-align: center;

}

div[class*='Dropdown'] {
  ul.ant-dropdown-menu {
    border-radius: 0 0 8px 8px;
    padding: 16px 16px 8px 4px;
    font-weight: 500;
  } 

  li.ant-dropdown-menu-item {
    background: white;
    padding-bottom: 15px;
    font-size: 15px;

    &:hover {
      color: $primary;
      transition: none;
    }
  }
}

div[class*='Dropdown'] {
  ul.ant-dropdown-menu {
    border-radius: 0 0 8px 8px;
    padding: 16px 16px 8px 4px;
    font-weight: 500;
  } 

  li.ant-dropdown-menu-item{
    background: white;
    padding-bottom: 15px;
    font-size: 15px;

    &:hover{
      color: $primary;
      transition: none;
    }
  }
}

div[class*='Input'] {
  .ant-input {
    font-size: 14px;
    font-weight: 400;
    
    &:focus {
      box-shadow: 0 0 0 2px rgba($color: $primary, $alpha: 0.2);
    }
  } 
}





/* stylelint-enable */
