@import '../../../assets/scss/variables';

.ContainerRow {
  padding-bottom: 60px;
  padding-top: 112px;
}

.TitleRow {
  padding-bottom: 48px;
}

.Question {
  color: $dark-text;
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: auto;
  margin: 0;
  padding: 0;
  text-align: left;
}

.Answer {
  margin-bottom: 12px;
  padding-left: 24px;
  text-align: justify;
}

.AnswerPoints {
  color: $dark-text;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
  margin-bottom: 8px;
  padding: 0;
  text-align: left;
}

.Link {
  color: $primary;
  cursor: pointer;

  &:hover {
    color: $dark-primary;
  }
}

.Margin {
  margin: 10px 0;
}
