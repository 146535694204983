@import '../../../assets/scss/variables';

.MainBackgroud {
  background-color: $background;
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-height: 100vh;
}

.Content {
  margin-top: 84px;
}
