@import '../../../assets/scss/variables';
@import '../../../assets/scss/breakpoints';

.ContainerRow {
  padding-bottom: 60px;
  padding-top: 48px;

  h1 {
    color: $dark-text;
    font-family: Gotham Rounded, sans-serif;
    font-size: 28px;
    font-weight: 700;
    line-height: auto;
    margin: 0 0 48px;
    padding: 0;
    text-align: center;
    @media only screen and (max-width: $sm) {
      font-size: 26px;
    }
  }

  h4 {
    color: $dark-text;
    font-family: Gotham Rounded, sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: auto;
    margin: 36px 0 16px;
    padding: 0;
    text-align: left;
    
  }

  h5 {
    color: $dark-text;
    font-family: Gotham Rounded, sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: auto;
    margin: 16px 0;
    padding: 0;
    text-align: left;
  }

  p {
    color: $dark-text;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    margin: 16px 0;
    padding: 0;
    text-align: justify;
  }
  
  a {
    color: $primary;
  }

  ol {
    padding-inline-start: 30px;
  }
  
  li {
    margin: 12px 0;

    &::marker {
      font-weight: 500;
    }
  }

}


