.Background {
  background: #fafafa;
  padding-bottom: 80px;
  padding-top: 50px;
}

.Title {
  margin-bottom: 50px;
  text-align: center;
}
